import React, { useEffect } from "react";
import { Link } from "react-router-dom";



function Home(props) {
	useEffect(() => {
		props.changeBG("home");
	});
	return (
		<main id="main" className="grid-container grid-container--home">

			<div>
				
				<h3 className="text-accent fs-500 ff-sans-cond uppercase">
					So, you want to travel to
					<span className="fs-10 ff-serif text-white letter-spacing-1 d-block">
						Ajimutho
					</span>
				</h3>
{/* 
				<p className="page-info text-accent fs-400">
					Let’s face real experience of news stories.
					 you might as well genuinely
					go to  space and not hover kind of on the edge of it. Well sit
					back, and real ride!
				</p> */}

				<p className="page-info text-accent fs-400">
					Let’s face real experience of gaming experience.
					 you might as well genuinely
					go to  space and not hover kind of on the edge of it. Well sit
					back, and real ride!
				</p>
				<br></br>
				<h4 className="text-accent fs-10 ff-sans-cond">
				<Link to="/privacypolicy" className="link">
						Privacy Policy
				</Link>
				</h4>

				<h4 className="text-accent fs-10 ff-sans-cond">

				<Link to="/destination" className="link">
						Terms of use
				</Link>

				</h4>
				
				<h4 className="text-accent fs-10 ff-sans-cond">
					
					<span className="fs-10 ff-serif text-white d-block">
						Blog
					</span>
				</h4>
				<h4 className="text-accent fs-10 ff-sans-cond">
					Contact
					<span className="fs-10 ff-serif text-white d-block">
						contact@menachemsoftware.com
					</span>
				</h4>
			</div>
			<div>
				<Link
					to="/destination"
					className="hover-effect large-button uppercase ff-serif text-dark bg-white"
				>
					Edge
				</Link>

			</div>

		</main>
	);
}

export default Home;
