import React, { useEffect } from "react";
import { Link } from "react-router-dom";


function Privacypolicy(props) {
	useEffect(() => {
		props.changeBG("privacypolicy");
	});
	return (

		<main id="privacy" className="grid-container grid-container--home">

			<div>
		

				<p className="page-info text-accent fs-400">
				The "fireman's lift" is a carrying technique used by firefighters to carry someone over their shoulder during rescues.
				</p>
	
				<br></br>
				<h4 className="text-accent fs-10 ff-sans-cond">
				<Link to="/privacypolicy" className="link">
						Privacy Policy
				</Link>
				</h4>

				<h4 className="text-accent fs-10 ff-sans-cond">

				<Link to="/destination" className="link">
						Terms of use
				</Link>

				</h4>
				
				<h4 className="text-accent fs-10 ff-sans-cond">
					
					<span className="fs-10 ff-serif text-white d-block">
						Blog
					</span>
				</h4>
				<h4 className="text-accent fs-10 ff-sans-cond">
					Contact
					<span className="fs-10 ff-serif text-white d-block">
						contact@menachemsoftware.com
					</span>
				</h4>
			</div>

</main>


	);
}

export default Privacypolicy;
